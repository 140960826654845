* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  position: relative;
}

p {
  margin: 0;
  align-self: center;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif;
}

button {
  -webkit-appearance: none;
  border: 0;
  outline: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

b,
strong {
  font-weight: 700;
  font-family: inherit;
}

.bg-grey {
  background-color: #ced4da;
}

.border-grey {
  border-color: #ced4da;
}

.box-shadow {
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.15);
}

main {
  min-height: 100vh;
  background-color: #efefef;
  padding-top: 0px;
  color: #333;
}

*:not(input):not(textarea) {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.planModal {
  border: 1px solid transparent;
}

/* main{min-height: 100vh; max-height: 100vh; background-color: #efefef; padding-top: 0px; color: #333;} */
/* .nav-bar{box-shadow: 0px 2px 4px rgba(0,0,0,0.15); z-index: 999; position: fixed; width:100%;} */
.container-library {
  padding-top: 50px;
}

.flagImages {
  margin-bottom: 3px;
  margin-right: 1px;
  width: 40px;
  height: 14px;
}

.menuIcons {
  font-size: 22px;
  line-height: 35px;
  padding-left: 5px;
  padding-right: 3px;
}

.menuItem label {
  margin-bottom: 0;
  float: left;
  line-height: 35px;
}

.menuItem .menuIcons {
  float: left;
  line-height: 35px;
}

.menuIconLibrary {
  margin-top: 1px;
  font-size: 22px;
  padding-left: 5px;
  padding-right: 3px;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.menuIconsHome {
  font-size: 22px;
  color: #fd7e14;
  padding-left: 5px;
  padding-right: 3px;
  float: left;
  line-height: 35px;
}

/* Fill the screen */
.image-container {
  height: 100vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 -15px;
  background-color: black;
  padding: 0px;
}

.container-screen-default {
  border-radius: 4px;
  min-height: calc(98vh - 140px);
  padding-top: 47px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 0px;
  background-color: #efefef;
}

.container-screen-default-agenda {
    border-radius: 4px;
    min-height: calc(98vh - 140px);
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
    background-color: #efefef;
}

.container-screen {
  border-radius: 4px;
  padding-top: 47px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 0px;
  background-color: transparent;
  padding-bottom: 30px;
}

.rectangleScanner {
  width: 100%;
  height: 100vh;
  /* background-color: red; */
  background-color: transparent;
}

.qrText {
  font-size: 18px;
}

.qrScanner {
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  pointer-events: none;
  overflow: hidden;
}

.qrScanner * {
  height: 100%;
}

.qrScanner:after {
  content: "";
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 250px auto;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("assets/img/bgMask.png");
}

.container-screen-iframe {
  border-radius: 4px;
  padding-top: 60px;
  padding-right: 0px;
  padding-left: 0px;
  background-color: transparent;
}

.imageTheme {
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position-y: bottom;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  /*padding-top: 60px;*/
}

.container-sidebar-setting {
  background-color: transparent;
  position: fixed;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 5px;
  max-height: 40px;
  height: 36px;
  min-height: 36px;
}

.container-sidebar {
  width: 100%;
  z-index: 1000;
  padding: 2px;
  cursor: default;
  background-color: white;
}

.nav-bar {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  z-index: 999;
  position: fixed;
  width: 100%;
  max-height: 60px;
  height: 58px;
  min-height: 60px;
}

.nav-bar.hidden {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.15) !important;
  background-color: transparent !important;
}

.nav-bar-tab {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  z-index: 998;
  position: fixed;
  width: 100%;
  /* margin-top: 60px;  */
  margin-top: 56px;
  margin-bottom: 5px;
  max-height: 44px;
  height: 40px;
  min-height: 40px;
  background: white;
}

.container-option label {
  text-indent: -23px;
  font-size: 20px;
  line-height: 24px;
  padding: 2px;
  padding-left: 9px;
  margin: 0px;
}


.loaderContainer {
  position: fixed;
  top: 35%;
  left: 42%;
  width: 100%
}

/* .container-tweet{border-radius: 4px; min-height: calc(98vh - 140px);} */

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #007bff;
}

/* .input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control {
  width: 100%;
  max-width: 500px;
  flex: 1 1 500px;
} */
/****************MATERIALIZE FIX ****************/

/***************MATERIALIZE FIX ********************/

/*****************GENERAL CLASSES *****************/
.emailPlanLink {
  color: white;
  text-decoration: none;

}

.loginScreen {
  padding-top: 120px;
  padding-left: 10px;
}

.app-content {
  min-height: 100vh;
  /* padding-top: 115px; */
  margin-top: -22px;

}

.dropdown-trigger {
  font-weight: 500;
}

.sidenav-header {
  background-image: url("assets/img/sidenav-bg.png");
  background-size: cover;
  background-position: center;
  padding: 40px 20px;
  position: relative;
}

nav .icon-header {
  margin: 0;
  height: 56px;
}

h6.brand-logo {
  margin: 10px 0;
  font-size: 24px;
}

h6.brand-logo>small {
  font-size: 15px;
  display: block;
}

.sidenav-header .logo {
  margin-bottom: 15px;
}

.logo img {
  max-width: 50%;
}

.sidenav .eventTitle {}

.sidenav .eventCode {}

.splashImageView {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  margin-top: 30%;
}

/*****************TWEET*******************/
.tweet-screen {
  padding: 15px;
}

#fixedbutton {
  /* position: fixed; */
  /* margin-top: 20px;
  margin-right: 15px; 
  border-radius: 60%;
  font-size: 25px; */
  /* bottom: 10px; */
  right: 10px;
  border-radius: 50%;
  margin-left: 15px;

  /* margin-right: 45%; */
}

@media (max-width: 540px) {
  #fixedbutton {
    /* position: fixed; */
    /* margin-top: 20px;
    margin-right: 15px; 
    border-radius: 60%;
    font-size: 25px; */
    /* bottom: 10px; */
    right: 10px;
    border-radius: 50%;
    /* margin-right: 38%; */
    margin-left: 15px;
  }
}

.participantTweets {
  max-height: 25vh;
}

.tweeterOrderRow {
  max-height: 45px;
}

.fixedbuttonIcon {
  font-size: 25px;
  /* margin: 2px; */
  margin-left: 6px;
  margin-top: 8px;
  margin-bottom: 13px;
  color: white
}

.fixedFooter {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 99;
}

.tweet-screen .card {
  margin-bottom: 0;
  transition: background-color .5s ease-in-out;
}

.tweet-screen .card .card-content {
  padding: 20px 5px;
}

header.fixed {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
}

.tweet-item {
  margin-bottom: 10px;
}

.anonymous-avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.card.lastItem {
  background-color: rgb(0, 150, 136, 0.4) !important;
}

/* **************************** TWEET SCREEN *********************************/

@media (min-width: 360px) {
  .container-tweet h5 {
    font-size: 18px;
    padding-bottom: 5px;
    text-transform: uppercase;
    color: #666;
  }


  /* .container-tweet h5:after{
  content: "";
  display: block;
  height:3px;
  width: 50px;
  background-color: #666;
  bottom: 0;  
} */
}

@media (min-width: 992px) {
  .container-tweet h5 {
    font-size: 22px;
    padding-bottom: 5px;
    text-transform: uppercase;
    color: #666;
  }

  /* .container-tweet h5:after{
    content: "";
    display: block;
    height:3px;
    width: 50px;
    background-color: #666;
    bottom: 0;  
  } */
}


.single-tweet {
  border-width: 1px;
  overflow: hidden;
  border-radius: 3px;
}

.head-single-tweet {
  color: #FFF;
  line-height: 20px;
  padding-top: 11px;
  padding-bottom: 11px;
}

.participantname-single-tweet {
  font-size: 15px;
}

.hours-single-tweet {
  font-size: 14px;
  margin-right: -26px;
}


#cardOptionContent {
  /* padding-left: 30px !important; */
  margin: 9px 0;
  /* question font size is : h5= font-size:  1.25rem; */
  font-size: 1.2rem;
}


.spinOption {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 30px;
  color: #007bff;
}


.form-check-label {
  margin-bottom: 14px;
  margin-left: 10px;
}

#dropDownICon {
  /* font-size:30px; */
  color: #8a8989;
  font-size: 15px;
}

#orderBtn {
  font-size: 12px;
  margin-right: 5px;
  color: rgb(65, 62, 62);
  cursor: pointer;
}


.custom-control-label::before,
.custom-control-label::after {
  width: 20px;
  height: 20px;
  margin-right: 4px;

}

.custom-control-label::after {
  top: 4px;
  left: -24px;

}

.custom-control-label::before {
  top: 4px;
  left: -24px;
  background-color: #c5c9ce;
  border-radius: 20px;

}

.close-btn-modal {
  background-color: transparent;
}

.close-btn-modal span {
  color: #FFF;
  font-size: 22px;
}

/******************************************* Header *******************************************/
nav .brand-logo {
  position: absolute;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  padding: 0;
}

.titleHeader {
  font-size: 20px;
  color: white;
}

.titleHeader.hidden {
  opacity: 0;
  pointer-events: none;
}

#navBarTabs {
  font-size: 14px;
  color: white;
  font-weight: 500;
  margin-top: 10px;

}

#navTab :hover {
  color: lightgray;
  cursor: pointer;
}

#navBarTabsDisabled {
  font-size: 14px;
  color: white;
  font-weight: 500;
  margin-top: 10px;

}

#navBarTabsDisabled :hover {
  color: lightgray;
  cursor: default;
}


#formHeader {
  width: 100%;
}

#tweet-col {
  margin-right: 10px;
}


.border-bottom-tweet {
  height: 4px;
  /* box-shadow: 0 2px 2px #b8b3b3; */
  max-width: 50%;
  /* margin-bottom: 8px; */
  margin-top: 5px;
  background-color: transparent;
}

.border-bottom-question {
  height: 4px;
  /* box-shadow: 0 2px 2px #b8b3b3; */
  max-width: 50%;
  /* margin-bottom: 8px; */
  margin-top: 5px;
  background-color: transparent;
  margin-left: 50%;

}

#headerNav {
  padding-bottom: 0px;
}

.logoHeader img {
  max-height: 90%;
  border: none;
  margin-right: 10px;
}

.logoHeader2 img {
  max-height: 90%;
  border: none;
}

.logoHeader2 {
  height: 50px;
  word-break: break-word;
}

.logoHeader {
  height: 50px;
  word-break: break-word;
}

.logoHeader label {
  font-size: 12px;
}

.logoGroup {
  flex-wrap: unset;
}

.imageThumbnail {
  padding: 0px;
  background-color: transparent;
  border: none;
  border-radius: .25rem;
  max-width: 35%;
}


/*********************************** Setting **************************************/
.sideBarStyle {
  background: white;
  width: 150px;
  height: 150px;
  z-index: 1000;
  /* overflow: hidden; */
  top: 35px;
  left: 15px !important;

}

.headerSetting {
  height: 40px;
}

/********************** Dashboard Screen *****************************************/
.alertAnswer {
  font-size: 16px;
}


.alert-survey {
  position: fixed;
  width: 400px;
  z-index: 1040;
  /* top: 10%;
  left: 20%; */
  border: 1px solid #e5e5e5;
  background-color: red;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  padding: 20px;
}

.centerDashboardScreen {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -200px;
  width: 400px;
}

.containerDshboard {
  border-radius: 4px;
  min-height: calc(98vh - 140px);
}

.settingContainer {
  /* margin-top: 64px; */
  width: 100%;
  z-index: 1000;
  padding: 2px;
  cursor: default;
  background-color: white;

}

.menuItem {
  cursor: pointer;
  background-color: white;
  border-bottom: 2px solid;
  /* border-bottom-color: #034286; */
  word-break: normal;


}

.menuItem :hover {
  /* background-color: rgb(221, 218, 218); */
}

.libraryIcon {
  color: #fd7e14;
}

.containerSplash {
  height: 100vh;
  width: 100%;
  /* padding-right: 15px;
  padding-left: 15px; */
  padding: 0px;
  margin-right: auto;
  margin-left: auto;
  background-color: rgb(230, 230, 230);
  padding-top: 0px;
}

.startText {
  padding-top: 4px;
  padding-bottom: 2px;
  padding-left: 18px;
  padding-right: 18px;
  background-color: #c7c7c7;
  border-radius: 10px;
  margin-top: 2px;
  width: 200px;

}

.footerSplash {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: rgb(59, 58, 58);
  z-index: 1000;
  background-color: rgb(230, 230, 230);
}

.fixedFooter {
  position: fixed;
  /* bottom: 10px; */
  left: 0;
  right: 0;
  z-index: 99;
  /* background-color: rgb(230, 230, 230); */
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.poolWaitingContainer {
  display: flex;
  height: 80vh;
}

.poolWaitingImg {
  width: 50vw;
  max-width: 300px;
  margin: auto;
}

.serverHeartBeat {
  width: 3px;
  height: 3px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
}

.serverHeartBeat.odd {
  background-color: green;
}

.serverHeartBeat.even {
  background-color: white;
}



/* Fit to the screen */
/* .image-container{
  height: 100vh;
  width: 100%;

}

 .image-container > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  padding: 0px;
}  */


/************************** Room Page *****************************/
.roomsIcon {
  font-size: 55px;
}

.roomsTitle {
  font-size: 25px;
  font-weight: 530;

}

.roomsRow {
  padding-top: 5%;
}

.roomBox {

  border-bottom: 4px solid;
  padding-left: 20px;
  padding-right: 20px;

}

/********************* Setting **************************/


.switch {
  position: relative;
  display: table;
  padding: 5px;
  float: right;
  border-radius: 34px;
  background-color: #666;
  min-width: 162px;
}

.inputOrder {
  transition: .3s left ease-in-out;
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: 4px;
  /* background-color: white; */
  border-radius: 50%;
  left: 8px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.textToggleOrder {
  padding-left: 30px;
  padding-right: 30px;
  transition: all .3s ease-in-out;
}

.switch.recent .textToggleOrder {}

.switch.recent .inputOrder {
  left: 132px;
}

.switch input {
  display: none;
}

.switchOFF {
  /* position: absolute; */
  cursor: pointer;
  left: 4px;
  right: 25px;
  bottom: 4px;
  background-color: rgb(138, 135, 135);
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  top: 1px;
}

.switchON {
  content: "";
  left: 4px;
  right: 25px;
  bottom: 4px;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
  background-color: #28a745;
  cursor: pointer;
  top: 1px;

}

.inputOFF {
  position: absolute;
  height: 19px;
  width: 24px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  margin-left: 6px;
}

.inputON {
  position: absolute;
  height: 19px;
  width: 24px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  margin-left: 35px;
}

.textToggleOff {
  margin-top: 4px;
  margin-right: 7px;
  font-size: 13px;
  font-weight: 300;
}

.textToggleOn {
  margin-top: 5px;
  margin-left: 8px;
  font-size: 13px;
  font-weight: 300;
}

.settingText {
  font-size: 12px;
}

.card-img-top {
  width: 100%;
  height: 20vh;
}

#navSideBar {
  background-color: red !important;
}

.sideNavClass .navigation {
  max-height: 80vh;
}


.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 300px;
  padding: 20px !important;
  text-align: left;
  background: #7fb7f3 !important;
  border-radius: 5px;
  -webkit-box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13) !important;
  color: rgb(36, 36, 36) !important;
  /* alert message body size */
  font-size: 25px !important;
}

.react-confirm-alert-body>h1 {
  /* alert message title size */
  font-size: 25px;
}

.react-confirm-alert-overlay {
  background: rgba(68, 68, 68, 0.44);
}

.react-confirm-alert-body .react-confirm-alert-button-group {
  justify-content: center;
  margin-top: 15px;
}

#react-confirm-alert>body {
  font-size: 2px;
}

.messageRow {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.badgeSettingIcon {
  font-size: 12px;
  /* margin-bottom: 67px; */
  position: absolute;
  left: 12px;
  top: 2px;
}

.eventMessageModal .modal-dialog-centered {
  padding-bottom: 15%;
}


#serviceFrameSend {
  height: 100vh;
  /*height: calc(100vh - 116px);*/
  width: 100%;
}

.serviceFrameSend {
  height: 100vh;
  width: 100%;
}

.iframeFullScreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999;
}


/************************ Question *********************/
.questionOptionsList {
  margin-block-start: 4px;
  margin-inline-start: 2px;
  padding-inline-start: 2px;
}

.starIconRating {
  font-size: 40px;
  padding-right: 5px;
}

.starIconRating2 {
  font-size: 35px;
  padding-right: 5px;
}

.questionCard {
  border: none;
}

.sidebarClass {
  width: 50%
}

.commentTextAreaField {
  padding: 10px;
}

.optionLikeIcon {
  font-size: 27px;
  padding-right: 10px;
}


.fontTextArea {
  font-size: 1.5rem;
  line-height: 2rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 500;
  /* line-height: 1.2; */
}

.optionBullet {
  font-size: 12px;
  padding-left: 20px;
}

.optionLabel {
  font-size: 1.5rem;

}

/*********************** Modal *************************/


/* Important part */
.eventMessageModal .modal-dialog {
  overflow-y: initial !important
}

.eventMessageModal .modal-body {
  height: 230px;
  overflow-y: auto;
}

/*********************** Library ***************************/

.card-body-files-icons {
  font-size: 45px;
  padding: 2px;

}

.card-body-files {
  font-size: 1.2rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 500;
  padding: 15px;
}

.container-option {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.questionText {
  /* line-height: 24px; */
  margin-bottom: 30px;
  padding-right: 10px;
  padding-top: 10px;
}

.card-body-question {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
  padding-bottom: 0;
  padding-top: 10px;
  /* font-size: 20px; */
  /* font-weight: 800px; */
}

/******************** Agenda ***************************/
.radio-cicle-label-agenda::before,
.radio-cicle-label-agenda::after {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.radio-cicle-label-agenda {
  font-size: 17px;
}


.imageAgenda {
  width: 100%;
}

.agendaLabel {
  word-break: break-all;
}

/******************** File Viewer ***************************/
.fileViewContainer {
  padding-top: 60px;
}

@media (max-width: 576px) {
  .starIconRating2 {
    font-size: 22px;
    padding-right: 5px;
  }
}

@media (min-width: 380px) and (max-width: 576px) {

  .qrText {
    font-size: 23px;
  }

  .card-body-files-icons {
    font-size: 35px;
    padding: 2px;

  }

  .card-body-files {
    font-size: 1rem;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    padding: 12px;
  }

  .radio-cicle-label-agenda::before,
  .radio-cicle-label-agenda::after {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-bottom: 3px;
  }

  .radio-cicle-label-agenda {
    font-size: 15px;
    margin-left: 15px;
  }

  .starIconRating {
    font-size: 35px;
    padding-right: 5px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .form-check-input {
    /* position: absolute; */
    margin-top: 3px;
    /* margin-left: -19px; */
    zoom: 1.4;
  }

  .card-body-question {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.55rem;
    padding-bottom: 0;
    padding-top: 10px;
    /* font-size: 20px; */
    /* font-weight: 800px; */
  }

  .switch {
    position: relative;
    display: table;
    padding: 5px;
    float: right;
    border-radius: 34px;
    background-color: #666;
    min-width: 112px;
  }

  .textToggleOrder {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 11px;
    text-align: center;
    transition: all .3s ease-in-out;
  }

  .switch.recent .inputOrder {
    left: 85px;
  }

  .starIconRating {
    font-size: 35px;
    padding-right: 5px;
  }

  .starIconRating2 {
    font-size: 22px;
    padding-right: 5px;
  }

  .form-check-input {
    /* position: absolute; */
    margin-top: 3px;
    /* margin-left: -15px; */
    zoom: 1.5;

  }

  .card-body-question {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.55rem;
    padding-bottom: 0;
    padding-top: 10px;
    font-size: 25px;
    font-weight: 600;
  }

}

@media (min-width: 768px) and (max-width: 991.98px) {

  .card-body-files-icons {
    font-size: 45px;
    padding: 3px;
  }

  .card-body-files {
    font-size: 1.2rem;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 500;
    padding: 18px;
  }

  .starIconRating {
    font-size: 40px;
    padding-right: 5px;
  }

  .starIconRating2 {
    font-size: 40px;
    padding-right: 5px;
  }

  .container {
    max-width: 100%;
  }

}

@media (min-width: 992px) {
  .form-check-input {
    /* position: absolute; */
    margin-top: 2px;
    /* margin-left: -15px; */
    /* zoom: 1.6; */
  }

  .card-body-question {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.55rem;
    padding-bottom: 0;
    padding-top: 10px;
    font-size: 25px;
    font-weight: 600;
  }
}

.sendBtn {
  font-size: 20px;
}

.tweetTextBox {
  padding: 8px;
  padding-top: 8px;
}

.containerUserTweets {
  padding-bottom: 70px;
}

.logInBtn {
  color: #dedede;
  background-color: #034286;
  border-color: #005cbf;

}

/******************** Login Screen ***************************/
#eventCodeLabel,
#eventCode,
#login,
[for="eventCode"] {
  font-size: 1.4rem;
}

#eventCodeLabel {
  background: white;
}

.app-version {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
